<template>
  <div>
    <base-section id="enduserparameters-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('paramSP.mainTitle')" />
          </v-col>
        </v-row>
        <v-row>
          <v-tabs vertical>
            <v-tab>
              <v-icon left> mdi-web </v-icon>
              {{ $t("paramSP.language") }}
            </v-tab>
            <!--- Language option  ------------------------------------------------->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>{{ $t("paramSP.language_line1") }}</p>
                  <v-radio-group v-model="parameters.language.default">
                    <v-radio
                      key="1"
                      :label="$t('paramSP.english')"
                      value="en"
                    />

                    <v-radio key="2" :label="$t('paramSP.french')" value="fr" />
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn @click="updateParameters">
                    {{ $t("button.validateQuestion") }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import {
  loadResourceParameters,
  saveResourceParameters,
} from "@/services/Parameters";
import { Logger } from "aws-amplify";

const logger = new Logger("ResourceParameters");

@Component({ name: "ResourceParameters" })
class ResourceParameters extends Vue {
  parameters = { language: { default: "fr" } };

  created() {
    loadResourceParameters()
      .then((result) => {
        if (result !== false) {
          // console.log(result)
          if (result.parameters !== undefined) {
            const parametersLoaded = JSON.parse(result.parameters);
            this.parameters.language.default =
              parametersLoaded.language.default;
            // console.log(this.parameters)
          }
        }
      })
      .catch((err) => logger.error(err));
  }

  updateParameters() {
    saveResourceParameters(this.parameters)
      .then((result) => {
        if (result === true) {
          this.$root.kdwsnackbar.alert("paramSP.alertUpdateOK");
        } else {
          this.$root.kdwsnackbar.alert("parameterLib.alertFailSave", -1);
        }
      })
      .catch((err) => {
        logger.error(err);
        this.$root.kdwsnackbar.alert("parameterLib.alertFailSave", -1);
      });
  }
}

export default ResourceParameters;
</script>
